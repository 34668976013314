
const API_URL = 'https://gloreto-api.onrender.com';
//const Hotel_ID ='62e763579f0908fc00b82722' //Montilla
const Hotel_ID ='62e6260e981a526a005ef9cb' //Luxury Gloreto@gmail.com 62e6260e981a526a005ef9cb
//const Hotel_ID ='62e76b10eac42ca100094c94' //TCalo
//const Hotel_ID ='62e76c8e93e8e6d588a4a9ae' //Ilang

export {
    Hotel_ID,
    API_URL,
}

//{_partition:"project=6127126cae94bc64a5e4b23a"}
//62e763579f0908fc00b82722
// _id
// 6129b276821f9e41b4209c5a
// original
// thumbnail